import http from '@/utils/http'
import qs from 'qs'

export const ordersubmitcustomorder = (params) =>{
    return http.postjson('/order/submitcustomorder',params)
}

export const payalipagepay = (params) =>{
    return http.get('/pay/alipagepay',params)
}
export const paywechatnativepay = (params) =>{
    console.log(params);
    return http.get('/pay/wechatnativepay',params)
}
export const ordergetorderinfo = (params) =>{
    return http.get('/order/getorderinfo',params)
}
export const ordercancelorder = (params) =>{
    return http.post(`/order/cancelorder?${qs.stringify(params)}`,params)
}
export const ordermyorderlist = (params) =>{
    return http.get('/order/myorderlist',params)
}
export const ordersubmitorder = (params) =>{
    return http.postjson('/order/submitorder',params)
}
export const paystate = (params) =>{
    return http.get('/pay/paystate?'+qs.stringify(params),params)
}


